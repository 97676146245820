import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogVehiclesService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  public get(filters: any, page: number, sort: MatSort | null, currentMark: number | null): Observable<any> {
    filters.colors = filters.colors?.join(',') || null;
    filters.marks = filters.marks?.join(',') || null;
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

    if (sort?.active) {
      filledFilters.order = sort.active;
    }

    if (sort?.direction) {
      filledFilters.order_direction = sort.direction;
    }

    if (currentMark) {
      filledFilters.mark_id = currentMark;
    }


    return this.httpClient.get<any>(`${ environment.api }/catalog_vehicles`, {
      params: {
        ...filledFilters,
        page,
      }
    });
  }
  public getNews(markId:any)
  {
    if(markId && markId > 0)
    {
      return this.httpClient.get<any>(`${ environment.api }/last_catalog_vehicles`, { params: { mark_id: markId } });
    }
    return this.httpClient.get<any>(`${ environment.api }/last_catalog_vehicles`);
  }

  public post(params: any): Observable<any> {
    return this.httpClient.post<any>(`${ environment.api }/catalog_vehicles`, params);
  }

  public put(id: number, params: any): Observable<any> {
    return this.httpClient.put<any>(`${ environment.api }/catalog_vehicles/${ id }`, params);
  }

  public delete(id: number) {
    return this.httpClient.delete<any>(`${ environment.api }/catalog_vehicles/${ id }`);
  }

  public getDetail(vehicleId: number): Observable<any> {
    return this.httpClient.get<any>(`${ environment.api }/catalog_vehicles/${ vehicleId }`);
  }

  public getCurrencies(): Observable<any> {
    return this.httpClient.get<any>(`${ environment.api }/currencies`);
  }

  public exportCatalog(filters: any, currentMark: number | null, sort: MatSort): Observable<any> {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value));
    const params: any = { ...filledFilters };
    if (currentMark) {
      params.mark_id = currentMark;
    }

    if (sort.active && sort.direction) {
      params.order = sort.active;
      params.type_order = sort.direction;
    }

    return this.httpClient.get(`${ environment.api }/catalog_vehicles/export`, {
      params,
      responseType: 'arraybuffer'
    });
  }

  public exportEquipmentCatalog(filters: any, currentMark: number | null, sort: MatSort): Observable<any> {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value));
    const params: any = { ...filledFilters };
    if (currentMark) {
      params.mark_id = currentMark;
    }

    if (sort.active && sort.direction) {
      params.order = sort.active;
      params.type_order = sort.direction;
    }

    return this.httpClient.get(`${ environment.api }/catalog_vehicles_equipment/export`, {
      params,
      responseType: 'arraybuffer'
    });
  }

  public import(file: File): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<any>(`${ environment.api }/catalog_vehicles/import`, formData);
  }

  public importEquip(file: File): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<any>(`${ environment.api }/catalog_vehicles_equipment/import`, formData);
  }

  public getIdmod(mark_id: number, model_id: number, version_id: number, serie_id: number, type_id: number){
    return this.httpClient.get<any>(`${ environment.api }/idmod`, {
      params:{
        mark_id,
        model_id,
        version_id,
        serie_id,
        type_id,
      }
    });
  }

  public createDiscount(discountData: any) {
    return this.httpClient.post<any>(`${ environment.api }/discounts`, {
      ...discountData
    });
  }

  public getDiscounts() {
    return this.httpClient.get<any>(`${ environment.api }/discounts`);
  }

  public changeVisibility(catalogId: number, visibility: number): Observable<any> {
    return this.httpClient.put(`${ environment.api }/catalog_vehicles/${catalogId}/visibility`, { visibility });
  }

}
